<template>
  <div class="page-header align-items-start min-vh-screen">
    <span class="mask bg-white"></span>
    <div class="container p-0" v-if="isLoadDone">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body" style="padding: 0rem 0rem">
            <div
              class="color-common pb-2 font-weight-bolder px-2 pt-2"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              Welcome,
              {{
                this.currentMember.name
                  ? this.currentMember.name
                  : this.currentMember.phone_number
              }}!
            </div>
            <div v-if="merchantMember.allow_setup_membership_tiering != 0">
              <div class="row mb-2 px-3">
                <div class="d-flex justify-content-between">
                  <div
                    class="d-flex align-items-center mb-1"
                    @click="gotoTransaction"
                  >
                    <h2 class="mb-0">{{ merchantMember.amount_format }}</h2>
                    <img
                      src="../assets/img/coins.png"
                      class="avatar avatar-sm border-radius-lg ps-1"
                      alt="user1"
                    />
                  </div>
                  <div
                    class="d-flex align-items-center mb-1"
                    @click="gotoTransaction"
                  >
                    <img
                      :src="pointIcon"
                      class="avatar avatar-sm border-radius-lg ps-1"
                      alt="user1"
                      style="height: 30px !important;"
                    />
                    <h2 class="mb-0 ps-2">{{ merchantMember.point_format }}</h2>
                  </div>
                </div>
                <div class="row text-size-12 ps-3" v-if="merchantMember.notice_transaction_point_expiry.length > 0">
                  <div v-for="(text, index) in merchantMember.notice_transaction_point_expiry" :key="index">
                    <em v-html="text"></em>
                  </div>
                </div>
                <div class="row text-size-12 ps-3" v-if="merchantMember.notice_transaction_amount_expiry.length > 0">
                  <div v-for="(text, index) in merchantMember.notice_transaction_amount_expiry" :key="index">
                    <em v-html="text"></em>
                  </div>
                </div>
                <div
                  style="color: #0000ee"
                  class="
                    d-flex
                    align-items-center
                    col-lg-12 col-12
                    ps-3
                    text-size-13 text-bold
                  "
                  @click="loyaltyTierBenefit()"
                >
                  {{ merchantMember.current_tier_name ? merchantMember.current_tier_name : "No tier" }}
                  <i class="material-icons-round opacity-10 fs-5"
                    >expand_more</i
                  >
                </div>
                <div class="col-lg-12 col-12 d-flex align-items-center">
                  <div class="col-lg-11 col-10 mx-auto">
                    <material-progress
                      :percentage="merchantMember.level_percentage"
                      variant="fill"
                      color="info"
                      class=""
                      style="height: 6px"
                    />
                  </div>
                  <div
                    class="col-lg-1 col-2 mx-auto ps-1"
                    v-if="merchantMember.tier_icon"
                  >
                    <img
                      :src="merchantMember.tier_icon"
                      class="avatar avatar-md border-radius-lg"
                      alt="user1"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-12 d-flex align-items-center" @click="loyaltyTierBenefit()">
                    <span class="text-size-12">{{ merchantMember.level_note}}</span> <i :style="`color: ${this.$store.state.labelColor} !important; font-size:18px`" class="material-icons-round ms-1">report</i>
                </div>
              </div>
            </div>
            <div
              id="carouselExampleCaptions"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  :style="`background-color: ${this.$store.state.labelColor} !important`"
                  v-for="(value, index) in imagesSlide"
                  :item="value"
                  :index="index"
                  :key="index"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  :data-bs-slide-to="index"
                  v-bind:class="index == 0 ? 'active' : ''"
                  :aria-current="index == 0 ? 'true' : ''"
                  :aria-label="`Slide ${index + 1}`"
                ></button>
              </div>
              <div class="carousel-inner">
                <div
                  v-for="(value, index) in imagesSlide"
                  :item="value"
                  :index="index"
                  :key="index"
                  class="carousel-item"
                  v-bind:class="index == 0 ? 'active' : ''"
                >
                  <a
                    :href="
                      value.destination_link
                        ? value.destination_link
                        : 'javascript:;'
                    "
                    :target="value.destination_link && '_blank'"
                  >
                    <img
                      :src="value.file_url"
                      class="d-block w-100 mobile-slide"
                    />
                  </a>
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
            <div>
              <div class="row mb-2 px-3 pt-2">
                <div
                  class="col-lg-12 col-12 color-common font-weight-bolder"
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  Available Vouchers
                </div>
              </div>
            </div>
            <div v-if="merchantMember.share_info_profile == 0" class="px-2">
              <div class="row voucher-item custom-shadow mb-2 ps-0 pe-0 ms-0 me-0">
                <div
                  class="
                    col-lg-4 col-4
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <img
                    src="../assets/img/reward.png"
                    class="avatar avatar-md border-radius-lg"
                    style="width: 70px; height: 70px"
                    alt="user1"
                  />
                </div>
                <div class="col-lg-8 col-8">
                    <div
                      class="col-lg-12 col-12 pe-0 title-voucher font-weight"
                    >
                      Complete profile to get a voucher for free!
                    </div>
                    <div class="col-lg-12 col-12">
                      <material-button
                        class="
                          mb-1
                          bg-green
                          px-4
                          font-expire font-weight
                          mt-2
                          btn-sm
                        "
                        :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
                        @click="editProfile()"
                      >
                        Add Profile
                      </material-button>
                    </div>
                </div>
              </div>
            </div>
            <div v-if="merchantVouchers.length > 0">
              <div class="wrap-card ps-1 pe-1" style="padding: 0px">
                <VoucherCard
                  :rewardPerTransaction="merchantMember.reward_type ?? 0"
                  :merchantVouchers="merchantVouchers"
                  :exchange="exchange"
                  :rewardDetail="rewardDetail"
                />
              </div>
            </div>
            <div v-else>
              <div
                class="d-flex justify-content-center p-5"
                style="background-color: #f0f2f5"
              >
                No rewards available
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
    </div>
    <MemorizeYourBirthday />
    <!-- Modal -->
    <Exchange
      :rewardPerTransaction="merchantMember.reward_type ?? 0"
      :voucherData="previewVoucherExchange"
      :amount_format="merchantMember.amount_format"
      :amount_in_account="merchantMember.amount_in_account"
      :point_format="merchantMember.point_format"
      :point_in_account="merchantMember.point"
      :confirm-exchange="confirmExchange"
    />
    <material-loading :active="loaderActive" />
    <ModalConfirm message="Do you want to exchange this voucher?"
            btnYes="Yes" :sycn-profile="processExchange" />
    <ModalSuccess
      :processAcction="gotoMyReward"
      message="Voucher has been exchanged successfully and saved to My Reward"
      buttonText="Go to My Reward"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import HomeService from "../services/home.service";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialProgress from "@/components/MaterialProgress.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import Exchange from "@/components/Exchange.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModalSuccess from "@/components/ModalSuccess.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import VoucherCard from "./components/VoucherCard.vue";
import "bootstrap/js/dist/modal";
import $ from "jquery";
import MemorizeYourBirthday from '@/components/MemorizeYourBirthday.vue'

export default {
  name: "my-transacions",
  components: {
    MaterialSnackbar,
    MaterialProgress,
    MaterialButton,
    Exchange,
    MaterialLoading,
    ModalSuccess,
    VoucherCard,
    MemorizeYourBirthday,
    ModalConfirm
  },
  data() {
    return {
      listTransaction: [],
      membershipCashback: 0,
      transactionType: [],
      imagesSlide: [],
      merchantVouchers: [],
      currentMember: {},
      merchantMember: {},
      previewVoucherExchange: {},
      snackbar: null,
      loaderActive: false,
      merchantCode: "",
      isLoadDone: false,
      pointIcon: ""
    };
  },
  created() {
    this.getDataHomePage();

    var session_id = this.$route.query.session_id;
    var reward_id = this.$route.query.reward_id;
    if(session_id && reward_id) { // call back after pay success - stripe
      this.showLoader();
      this.isLoadDone = false;
      HomeService.processExchangeByCard(reward_id, session_id).then(
        (response) => {
          if (response.data.result.isSuccess) {
            // const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
            // var url = location.protocol + '//' + location.host+"/"+merchantCode+"/home";
            // window.location.href = url;
            this.getDataHomePage();
            this.snackbar = "success";
            this.message = "Exchange Successfully!";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    }

    if (window.innerWidth <= 1200) {
      if ($("#app").hasClass("g-sidenav-pinned")) {
        this.toggleSidebar();
      }
    }
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";

    const memorize = this.$route.query.memorize ?? null;
    if (memorize) {
      $(".bottom_modal_memorize").click();
    }
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "navbarMinimize",
    ]),
    ...mapActions(["setlabelColor", "setLabelColor", "setButtonColor"]),
    ...mapState(["isPinned"]),
    gotoMyReward() {
      $(".btn-close").click();
      this.$router.push(this.merchantCode + "/my-rewards");
    },
    toggleSidebar() {
      this.navbarMinimize();
    },
    editProfile() {
      this.$router.push("/minty/profile");
    },

    exchange(voucher) {
      this.previewVoucherExchange = voucher;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    rewardDetail(reward_id) {
      this.$router.push(this.merchantCode + "/reward-detail/" + reward_id);
    },

    gotoTransaction() {
      this.$router.push(this.merchantCode + "/my-transactions");
    },
    getDataHomePage() {
      this.closeSnackbar();
      this.getDataHomePageCommon();
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    loyaltyTierBenefit() {
      this.$router.push(this.merchantCode + "/loyalty-tier-benefit");
    },

    getDataHomePageCommon() {
      this.showLoader();
      this.isLoadDone = false;
      HomeService.getDataHomePage().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.imagesSlide = response.data.data.slides;
            this.merchantVouchers = response.data.data.vouchers;
            this.currentMember = response.data.data.member;
            this.merchantMember = response.data.data.merchant_member;
            this.pointIcon = response.data.data.point_icon;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    confirmExchange() {
      $('.btn-confirm-modal').click();
    },

    processExchange() {
      $('.btn-close-confirm-modal').click();
      this.closeSnackbar();
      this.showLoader();
      const rewardId = this.previewVoucherExchange.id ?? 0;
      HomeService.processExchange(rewardId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.getDataHomePageCommon();
            $(".btn-close").click();
            $(".btn-success-modal").click();
          } else {
            $(".btn-close").click();
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style>
.col-lg-8 {
  text-align: left;
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.group-month {
  font-weight: 400;
}

.group-month {
  margin-right: 10px;
}

.brand-name {
  cursor: pointer;
}

.font-level-member {
  font-size: 12px;
}

.font-level-member-extra:hover {
  text-decoration: underline;
  text-decoration-color: #0366fc;
  cursor: pointer;
}

.font-expire {
  font-size: 12px;
}

.font-sign {
  font-size: 22px;
}

.title-voucher {
  font-size: 14px;
}

/*  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.card {
  /* width: 400px; */
  height: 120px;
  border-radius: 0 !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #b6d7a8 !important;
  padding: 10px 10px;
  position: relative;
}

.card::after {
  position: absolute;
  content: "";
  height: 40px;
  right: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 40px;
  background-color: #ffffff;
  width: 40px;
}

.card::before {
  position: absolute;
  content: "";
  height: 40px;
  left: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 40px;
  background-color: #ffffff;
  width: 40px;
}

.font-weight {
  font-weight: 600;
}

.notification-icon--fixed {
  position: relative;
  color: #fff;
  background-color: #ea9999;
  border-radius: 50%;
  font-family: "Roboto";

  /* Alignment */
  line-height: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  /* Adjust as required: */
  padding: 10px;
}

/* Height = width */
.notification-icon--fixed::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.max-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 50px;
}

/* Full screen modal menu indicators */

a.has-sub:after {
  font-family: "FontAwesome";
}

a.has-sub:after {
  content: "\f107";
  margin-left: 1rem;
}

a.has-sub[aria-expanded="true"]:after {
  content: "\f106";
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.font-custom {
  font-size: 14px;
}

.carousel-indicators {
  bottom: -20px !important;
}

.mobile-slide {
  aspect-ratio: 2 / 1;
}

@media (min-width: 900px) {
  .mobile-slide {
    max-height: 400px;
  }
}

@media (max-width: 500px) {
  .mobile-slide {
    max-height: 200px;
  }

  h2 {
    font-size: 22px !important;
  }
}
</style>