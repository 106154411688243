import api from "./api";
import TokenService from "./token.service";

class AuthService {
  logout() {
    TokenService.removeUser();
  }

  validatePhoneNumber(phone_number) {
    return api.post("member/validate-phone", { phone_number })
  }

  sendOtp({ phone_number, is_profile, invite_code, i_agree }) {
    return api
      .post("member/send-otp", {
        phone_number,
        is_profile,
        invite_code,
        i_agree
      })
      .then((response) => {
        return response.data;
      });
  }

  loginOtp({ phone_number, otp, i_agree, passcode = '', is_empty_passcode = false }) {
    return api
      .post("member/login-otp", {
        phone_number,
        otp,
        i_agree,
        passcode,
        is_empty_passcode
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          TokenService.setMember(response.data);
        }
        return response.data;
      });
  }

  loginGoogle({ access_token, invite_code }) {
    return api
      .post("member/login-google", {
        access_token,
        invite_code
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          TokenService.setMember(response.data);
        }
        return response.data;
      });
  }

  sendOTP(phone_number) {
    return api
      .post("/member/send-otp", {
        phone_number
      })
  }

  sendLinkForgotPasscode(phone_number, link) {
    return api.post("member/send-link-forgot-passcode", { phone_number, link })
  }

  updatePasscode(reset_pass_code, new_passcode) {
    return api.post("member/update-passcode", { reset_pass_code, new_passcode })
  }

  validateLinkForgetPasscodeByCode(reset_pass_code) {
    return api.post("member/validate-link-forget-passcode-by-code", { reset_pass_code })
  }

  getOneTimePassCode(phone_number) {
    return api.post("member/get-one-time-passcode", { phone_number })
  }

  sendResetToInitialPasscode(phone_number) {
    return api.post("member/send-reset-to-initial-passcode", { phone_number })
  }

  changePasscode({ passcode, phone_number }) {
    return api
      .post("member/change-passcode", {
        phone_number,
        passcode
      })
      .then((response) => {
        // if (response.data.result.isSuccess) {
          
        // }
        return response.data;
      });
  }

  checkTokenHasExpired() {
    return api.get("member/check-token-has-expired")
  }
}

export default new AuthService();
