<template>
  <div class="page-header align-items-start min-vh-screen">
    <span class="mask bg-white"></span>
    <div class="container p-0">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto" v-if="isLoadDone">
          <div class="card-body" style="padding: 0rem 0rem">
            <div>
              <div class="color-common pb-2 font-weight-bolder px-2 pt-2">
                <h3 class="ps-1" :style="`color: ${this.$store.state.labelColor} !important`">STORE</h3>
              </div>
              <search-input
                :onChangesearchKeyword="onChangesearchKeyword"
                placeholder="Find voucher bundle"
              />
              <div v-if="merchantVouchers.length > 0">
                <div class="wrap-card ps-1 pe-1" style="padding: 0px">
                  <VoucherCard
                    :rewardPerTransaction="merchantMember.reward_type ?? 0"
                    :merchantVouchers="merchantVouchers"
                    :exchange="exchange"
                    :bundleDetail="bundleDetail"
                    :isBundle="true"
                  />
                </div>
              </div>
              <div v-else>
                <div
                  class="d-flex justify-content-center p-5"
                  style="background-color: #f0f2f5"
                >No voucher available</div>
              </div>
            </div>
          </div>
        </div>
        <SortFilterToolbar
          :filterArrRewardType="filterArrRewardType"
          :filterArrStore="filterArrStore"
        />
      </div>
      <div class="position-fixed top-1 end-1 z-index-2">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title
          date
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
    </div>
    <!-- Modal -->
    <Sort :filterData="filterData" :changeSortType="changeSortType" />
    <Filter
      :filterRewardType="filterRewardType"
      :filterArrRewardType="filterArrRewardType"
      :stores="stores"
      :filterStore="filterStore"
      :filterArrStore="filterArrStore"
      :filterData="filterData"
      :resetData="resetData"
    />
    <Exchange
      :rewardPerTransaction="merchantMember.reward_type ?? 0"
      :voucherData="previewVoucherExchange"
      :amount_format="merchantMember.amount_format"
      :amount_in_account="merchantMember.amount_in_account"
      :point_format="merchantMember.point_format"
      :point_in_account="merchantMember.point"
      :is_disabled="previewVoucherExchange.is_disabled"
      :is_disabled_by_card="previewVoucherExchange.is_disabled_by_card"
      :confirm-exchange="confirmExchange"
    />
    <material-loading :active="loaderActive" />
    <ModalConfirm
      message="Do you want to exchange this voucher?"
      btnYes="Yes"
      :sycn-profile="processExchange"
    />
    <ModalSuccess
      :processAcction="gotoMyReward"
      message="Voucher has been exchanged successfully and saved to My Reward"
      buttonText="Go to My Reward"
    />
  </div>
</template>
  
  <script>
import { mapMutations, mapActions, mapState } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import RewardService from "../services/reward.service";
import "bootstrap/dist/js/bootstrap.bundle";
import Exchange from "@/components/Exchange.vue";
import Sort from "@/components/Sort.vue";
import SortFilterToolbar from "@/components/SortFilterToolbar.vue";
import Filter from "@/components/Filter.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModalSuccess from "@/components/ModalSuccess.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import VoucherCard from "./components/VoucherCard.vue";
import "bootstrap/js/dist/modal";
import $ from "jquery";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "my-transacions",
  components: {
    MaterialSnackbar,
    Exchange,
    MaterialLoading,
    ModalSuccess,
    Sort,
    Filter,
    SortFilterToolbar,
    VoucherCard,
    ModalConfirm,
    SearchInput,
  },
  data() {
    return {
      listTransaction: [],
      membershipCashback: 0,
      transactionType: [],
      merchantVouchers: [],
      currentMember: {},
      merchantMember: {},
      previewVoucherExchange: {},
      snackbar: null,
      loaderActive: false,
      merchantCode: "",
      stores: [],
      filterArrRewardType: [],
      filterArrStore: [],
      params: {},
      sortType: "1",
      isLoadDone: false,
      pointIcon: "",
    };
  },
  created() {
    this.getDataHomePage();
    this.getBranches();

    var session_id = this.$route.query.session_id;
    var reward_id = this.$route.query.reward_id;
    if (session_id && reward_id) {
      // call back after pay success - stripe
      this.showLoader();
      this.isLoadDone = false;
      RewardService.processExchangeVoucherBundleByCard(reward_id, session_id).then(
        (response) => {
          if (response.data.result.isSuccess) {
            // const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
            // var url = location.protocol + '//' + location.host+"/"+merchantCode+"/home";
            // window.location.href = url;
            this.getDataHomePage();
            this.getBranches();
            this.snackbar = "success";
            this.message = "Exchange Successfully!";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    }

    if (window.innerWidth <= 1200) {
      if ($("#app").hasClass("g-sidenav-pinned")) {
        this.toggleSidebar();
      }
    }
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "navbarMinimize",
    ]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
    ...mapState(["isPinned"]),

    gotoMyReward() {
      $(".btn-close").click();
      this.$router.push(this.merchantCode + "/my-rewards");
    },

    toggleSidebar() {
      this.navbarMinimize();
    },

    exchange(voucher) {
      this.previewVoucherExchange = voucher;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    getDataHomePage() {
      this.closeSnackbar();
      this.getVoucherBundle(this.params);
    },

    bundleDetail(rewardId) {
      this.$router.push(this.merchantCode + "/voucher-bundle/" + rewardId);
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    getVoucherBundle(params) {
      this.showLoader();
      this.isLoadDone = false;
      RewardService.getListVoucherBundle(params).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.merchantVouchers = response.data.data.vouchers;
            this.currentMember = response.data.data.member;
            this.merchantMember = response.data.data.merchant_member;
            this.pointIcon = response.data.data.point_icon;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.isLoadDone = true;
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    confirmExchange() {
      $(".btn-confirm-modal").click();
    },

    processExchange() {
      $(".btn-close-confirm-modal").click();
      this.closeSnackbar();
      this.showLoader();
      const rewardId = this.previewVoucherExchange.id ?? 0;
      RewardService.processExchangeVoucherBundle(rewardId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close").click();
            this.getVoucherBundle();
            $(".btn-close").click();
            $(".btn-success-modal").click();
          } else {
            $(".btn-close").click();
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    changeSortType(event) {
      this.sortType = event.target.value;
    },

    filterRewardType(value) {
      const index = this.filterArrRewardType.indexOf(value);
      if (index > -1) {
        this.filterArrRewardType.splice(index, 1);
      } else {
        this.filterArrRewardType.push(value);
      }
    },

    filterStore(value) {
      const index = this.filterArrStore.indexOf(value);
      if (index > -1) {
        this.filterArrStore.splice(index, 1);
      } else {
        this.filterArrStore.push(value);
      }
    },

    getBranches() {
      this.stores = [];
      this.showLoader();
      RewardService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.stores = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    filterData() {
      $(".btn-close").click();
      $(".x-close").click();
      this.params = {
        sort_type: this.sortType,
        filter_reward_type: this.filterArrRewardType,
        filter_store: this.filterArrStore,
      };
      this.getVoucherBundle(this.params);
    },
    resetData() {
      $(".btn-unclick").css({ "background-color": "#E3EBF7", color: "#000" });
      $(".btn-unclick2").css({ "background-color": "#E3EBF7", color: "#000" });
      this.filterArrRewardType = [];
      this.filterArrStore = [];
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
  <style>
/* .col-7 {
    text-align: -webkit-right;
  } */
.col-lg-8 {
  text-align: left;
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.group-month {
  font-weight: 400;
}

.group-month {
  margin-right: 10px;
}

.brand-name {
  cursor: pointer;
}

.font-level-member {
  font-size: 12px;
}

.font-expire {
  font-size: 12px;
}

.font-sign {
  font-size: 22px;
}

.title-voucher {
  font-size: 14px;
}

/*  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.card {
  /* width: 400px; */
  height: 120px;
  border-radius: 0 !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #b6d7a8 !important;
  padding: 10px 10px;
  position: relative;
}

.card::after {
  position: absolute;
  content: "";
  height: 40px;
  right: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 40px;
  background-color: #ffffff;
  width: 40px;
}

.card::before {
  position: absolute;
  content: "";
  height: 40px;
  left: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 40px;
  background-color: #ffffff;
  width: 40px;
}

.font-weight {
  font-weight: 600;
}

.notification-icon--fixed {
  position: relative;
  color: #fff;
  background-color: #ea9999;
  border-radius: 50%;
  font-family: "Roboto";

  /* Alignment */
  line-height: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  /* Adjust as required: */
  padding: 10px;
}

/* Height = width */
.notification-icon--fixed::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.max-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 50px;
}

/* Full screen modal menu indicators */

a.has-sub:after {
  font-family: "FontAwesome";
}

a.has-sub:after {
  content: "\f107";
  margin-left: 1rem;
}

a.has-sub[aria-expanded="true"]:after {
  content: "\f106";
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.font-custom {
  font-size: 14px;
}
</style>