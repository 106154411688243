<template>
  <div class="page-header align-items-start">
    <span class="mask bg-white"></span>
    <div class="container" v-if="isShowLayout">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 pt-2 custom-icon-back py-2">
          <a @click="landing()">
            <i
              class="fas fa-chevron-left color-common"
              :style="`color: ${this.$store.state.labelColor} !important`"
            ></i>
          </a>
        </div>
        <div
          class="col-lg-12 col-md-8 col-12 mx-auto vh-90 pb-90 content-scroll"
        >
          <div class="mb-3" style="position: relative">
            <div class="row">
              <div class="voucher-item">
                <div class="d-flex justify-content-between">
                  <div class="col-4">
                    <img
                      :src="`${voucherBundleDetail.icon_url}`"
                      class="voucher-image"
                      alt="user1"
                    />
                  </div>
                  <div class="col-8 ps-0">
                    <div class="d-flex align-items-center">
                      <strong
                        class="text-size-13 ps-0 w-70"
                        :style="`color: ${this.$store.state.labelColor} !important`"
                        >{{ voucherBundleDetail.title ?? "" }}</strong
                      >
                      <div
                        class="amount-exchange w-30 pe-1"
                        v-if="merchantMember.reward_type == 1 && !voucherBundleDetail.is_disabled_btn"
                      >
                        {{ voucherBundleDetail.amount_text }}
                      </div>
                      <div
                        class="amount-exchange w-30 pe-1"
                        v-if="merchantMember.reward_type == 2 && !voucherBundleDetail.is_disabled_btn"
                      >
                        {{ voucherBundleDetail.point_text }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <em class="text-size-13">{{
                        voucherBundleDetail.number_of_vouchers_text
                      }}</em>
                    </div>
                    <div class="d-flex">
                      <em class="text-size-13"
                        >Valid for:
                        {{ voucherBundleDetail.expiry_date_text }}</em
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row mb-2"
            v-if="voucherBundleDetail.number_of_vouchers.total > 0"
          >
            <div
              class="color-common font-weight-bolder ps-0"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              Vouchers
            </div>
          </div>
          <div
            class="row mb-2"
            v-if="voucherBundleDetail.number_of_vouchers.total > 0"
          >
            <div
              class="d-flex bd-highlight"
              v-for="(voucher, index) in voucherBundleDetail.number_of_vouchers
                .detail"
              :key="index"
            >
              <i class="fas fa-check pe-2 pt-1" style="color: #049e0e"></i>
              <p class="font-content text-dark">
                {{
                  voucher.title +
                  (voucher.total > 1 ? " x" + voucher.total : "")
                }}
              </p>
            </div>
          </div>
          <div v-if="isReward">
            <div class="d-flex justify-content-center mb-3">
            <ul class="reward-tab w-100" :style="styleObject">
              <li
                class="nav-item active"
                id="nav_item_1"
                @click="changeType(1)"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                <strong>Available</strong>
              </li>
              <li
                class="nav-item"
                id="nav_item_2"
                @click="changeType(2)"
                :style="`color: ${this.$store.state.labelColor} !important`"
              >
                <strong>Used/Expired</strong>
              </li>
            </ul>
          </div>
          </div>
          <div
            style="position: relative"
            v-if="voucherBundleDetail.number_of_vouchers.total && isReward"
          >
            <div class="row" v-if="myRewards.length">
              <div
                class="voucher-item col-lg-6 col-12 col-md-6 mb-2"
                v-for="(value, index) in myRewards"
                :key="index"
              >
                <div class="d-flex justify-content-between">
                  <div
                    class="col-4"
                    @click="
                      getRewardDetail(
                        value.member_reward_id,
                        value.expiry_date_text
                      )
                    "
                  >
                    <img
                      :src="`${value.icon_url}`"
                      class="voucher-image"
                      alt="user1"
                    />
                  </div>
                  <div class="col-8 ps-0">
                    <div
                      class="d-flex align-items-center"
                      @click="
                        getRewardDetail(
                          value.member_reward_id,
                          value.expiry_date_text
                        )
                      "
                    >
                      <strong
                        class="text-size-13 ps-0 w-70"
                        :style="`color: ${this.$store.state.labelColor} !important`"
                        >{{ value.reward_title ?? "" }}</strong
                      >
                    </div>
                    <div class="d-flex">
                      <em class="text-size-13"
                        >Valid until: {{ value.expiry_date_text }}</em
                      >
                    </div>
                    <div
                      v-if="value.locked"
                      class="mb-1 col-lg-5 d-flex justify-content-center align-items-center btn-exchange px-3 status-lock"
                    >
                      Locked
                    </div>
                    <div
                      v-if="value.use"
                      class="mb-1 col-lg-5 d-flex justify-content-center align-items-center btn-exchange px-3"
                    >
                      Used
                    </div>
                    <div
                      v-else-if="value.isExpired"
                      class="mb-1 col-lg-5 d-flex justify-content-center align-items-center btn-exchange px-3"
                    >
                      Expired
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div
                class="d-flex justify-content-center p-5"
                style="background-color: #f0f2f5"
              >
                No rewards available
              </div>
            </div>
          </div>
          <div class="row mb-2" v-if="voucherBundleDetail.terms_arr.length">
            <div
              class="color-common font-weight-bolder ps-0"
              :style="`color: ${this.$store.state.labelColor} !important`"
            >
              Terms & Conditions
            </div>
          </div>
          <div
            v-for="(value, index) in voucherBundleDetail.terms_arr"
            :item="value"
            :index="index"
            :key="index"
            class="row mb-2"
          >
            <div class="d-flex bd-highlight" v-if="value">
              <i class="fas fa-check pe-2 pt-1"></i>
              <p class="font-content text-dark c-terms-conditions">{{ value }}</p>
            </div>
          </div>
          <div
            class="text-center btn-flex"
            v-if="!voucherBundleDetail.is_disabled_btn"
          >
            <button
              class="btn mb-3 btn-success btn-md col-lg-4 col-md-5 col-6 bg-color-common"
              :disabled="voucherBundleDetail.is_disabled_btn"
              :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
              data-bs-toggle="modal"
              data-bs-target="#bottom_modal"
            >
              Exchange
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <Exchange
      :rewardPerTransaction="merchantMember.reward_type ?? 0"
      :voucherData="voucherBundleDetail"
      :amount_format="merchantMember.amount_format"
      :amount_in_account="merchantMember.amount_in_account"
      :point_format="merchantMember.point_format"
      :point_in_account="merchantMember.point"
      :is_disabled="voucherBundleDetail.is_disabled"
      :is_disabled_by_card="voucherBundleDetail.is_disabled_by_card"
      :confirm-exchange="confirmExchange"
    />

    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title
        date
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
    <ModalConfirm
      message="Do you want to exchange this voucher?"
      btnYes="Yes"
      :sycn-profile="processExchange"
    />
    <ModalSuccess
      :processAcction="gotoMyReward"
      message="Voucher has been exchanged successfully and saved to My Reward"
      buttonText="Go to My Reward"
    />
    <QrcodeReward
      :rewardDetail="rewardDetail"
      :merchantMember="merchantMember"
      :isShowQr="typeChecked == 2 ? false : true"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import RewardService from "../services/reward.service";
import "bootstrap/dist/js/bootstrap.bundle";
import Exchange from "@/components/Exchange.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModalSuccess from "@/components/ModalSuccess.vue";
import $ from "jquery";
import HomeService from "../services/home.service";
import QrcodeReward from "@/components/QrcodeReward.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";

export default {
  name: "reward-detail",
  components: {
    MaterialSnackbar,
    Exchange,
    MaterialLoading,
    ModalSuccess,
    QrcodeReward,
    ModalConfirm
  },
  data() {
    return {
      voucherBundleDetail: {},
      merchantMember: {},
      loaderActive: false,
      snackbar: null,
      isShowLayout: false,
      merchantCode: "",
      myRewards: [],
      rewardsUsed: [],
      rewardsUnused: [],
      rewardDetail: {},
      isReward: this.$route.query.isReward ?? false,
      memberVoucherBundleId: this.$route.query.memberVoucherBundleId ?? 0,
      type: 1
    };
  },
  computed: {
    styleObject() {
      return {
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  created() {
    this.getVoucherBundleDetail();

    var session_id = this.$route.query.session_id;
    var id = this.$route.query.id;
    if (session_id && id) {
      // call back after pay success - stripe
      this.showLoader();
      this.isLoadDone = false;
      HomeService.processExchangeByCard(id, session_id).then(
        (response) => {
          if (response.data.result.isSuccess) {
            // const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
            // var url = location.protocol + '//' + location.host+"/"+merchantCode+"/home";
            // window.location.href = url;
            this.getVoucherBundleDetail();
            this.snackbar = "success";
            this.message = "Exchange Successfully!";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    }
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

    changeType(type) {
      $(".nav-item").removeClass("active");
      $("#nav_item_" + type).addClass("active");

      if (type == 1) {
        this.myRewards = this.rewardsUnused;
      } else {
        this.myRewards = this.rewardsUsed;
      }
    },

    getVoucherBundleDetail() {
      // this.closeSnackbar()
      this.showLoader();
      var dataForm = {
        id: this.$route.params.id,
        member_voucher_bundle_id: this.memberVoucherBundleId,
        is_reward: this.isReward,
      };

      RewardService.getVoucherBundleDetail(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.voucherBundleDetail = response.data.data.voucher;
            this.merchantMember = response.data.data.merchant_member;
            this.rewardsUsed = response.data.data.voucher_in_bundle_used;
            this.rewardsUnused = response.data.data.voucher_in_bundle_unused;
            this.myRewards = this.rewardsUnused;
            this.isShowLayout = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    processExchange() {
      $(".btn-close-confirm-modal").click();
      this.closeSnackbar();
      this.showLoader();
      const rewardId = this.voucherBundleDetail.id ?? 0;
      RewardService.processExchangeVoucherBundle(rewardId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close").click();
            this.isReward = true;
            this.$router.push({path: this.$route.path, query: { ...this.$route.query, isReward: true }})
            this.getVoucherBundleDetail();
            $(".btn-close").click();
            $(".btn-success-modal").click();
          } else {
            $(".btn-close").click();
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    getRewardDetail(rewardId, expiry_date_text = null) {
      this.showLoader();
      RewardService.getRewardDetail(rewardId, 1).then(
        (response) => {
          if (response.data.result.isSuccess) {
            let rewardData = response.data.data.reward;
            if (expiry_date_text) {
              rewardData.expiry_date_text = expiry_date_text;
            }
            this.rewardDetail = rewardData;
            this.merchantMember = response.data.data.merchant_member;
            $(".btn-close").click();
            $(".btn-qrcode-modal").click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    gotoMyReward() {
      $(".btn-close").click();
      this.$router.push(this.merchantCode + "/my-rewards");
    },

    confirmExchange() {
      $(".btn-confirm-modal").click();
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    async landing() {
      $(".btn-close").click();
      this.$router.go(-1);
    },
  },
};
</script>
<style scope>
.reward-tab {
  margin: 0px !important;
  padding: 0px;
  width: 90% !important;
}

.reward-tab li {
  list-style: none;
  float: left;
  width: 50%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.reward-tab li.active {
  border-bottom: 2px solid var(--label-color);
  color: var(--label-color);
}
.where-to-use a {
  color: #007bff !important;
  text-decoration: underline !important;
}

.transaction-detail {
  font-weight: 500;
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.font-custom {
  font-size: 14px;
}

.custom-icon-back {
  font-size: 25px;
}

.text-custom {
  color: black !important;
  font-weight: 500;
}

.group-month {
  font-weight: 400;
}

.group-month {
  margin-right: 10px;
}

.brand-name {
  cursor: pointer;
}

.font-level-member {
  font-size: 12px;
}

.font-content {
  font-size: 13px;
  font-weight: 600;
}

.font-sign {
  font-size: 22px;
}

.title-voucher {
  font-size: 14px;
}

.font-size-custom {
  font-size: 30px;
  top: 25px;
}
.voucher-item {
  position: relative;
  padding: 5px !important;
  background: hsla(0, 0%, 100%, 0.5);
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}
.amount-exchange {
  color: #000000;
  font-weight: bold;
  text-align: right;
  min-width: 100px !important;
}
.btn-flex {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
}
.vh-90 {
  min-height: 90vh;
}
.voucher-image {
  border-radius: 10px;
}
.btn-exchange {
  position: absolute;
  right: 10px;
  bottom: 5px;
  min-width: 80px;
  max-width: 150px;
}
.status-lock {
  color: #fbc531 !important;
  border: 1px solid #fbc531 !important;
}
@media only screen and (max-width: 1024px) {
  .font-size-custom {
    font-size: 16px;
    top: 6px;
    right: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .font-size-custom {
    font-size: 16px;
    top: 6px;
    right: 10px;
  }
}
</style>
